import { clearCurrency } from '../components/table/utils/isCurrencyValidator'

export const recriatedTable = (
  newTable: any,
  companyId: number,
  hashEmpresa: string | undefined,
  companyName: string,
  companyPhone: string,
  cnpjCompany: string
) => {
  const dados = {
    companyId: companyId,
    observation: '',
    Hash: hashEmpresa,
    Origin: 'IMPXLS',
    NameSeller: companyName,
    PhoneSeller: companyPhone,
    DateExpirationLink: '1900-01-01',
  }

  const ordersTable: any[] = []

  const numberOrders = newTable.map((item: string) => {
    return item[0]
  })

  const numberOrdersDeleteDuplicate = newTable.filter(
    (order: any, i: number) => numberOrders.indexOf(order[0]) === i
  )

  for (let items of numberOrdersDeleteDuplicate) {
    const ordersAgrouped = newTable.filter((order: any) => {
      if (order[0] === items[0]) {
        return order
      }
    })

    const products = ordersAgrouped.map((order: any) => {
      return {
        name: order[15].toString() || '',
        quantity: order[16] || 0,
        unitaryValue: clearCurrency(order[17].toString()) || 0,
        amount: clearCurrency(order[18].toString()) || 0,
        ReferenceId: '',
        sku: order[19].toString() || '',
      }
    })

    const order = {
      ...dados,
      referenceId: items[0].toString(),
      client: {
        name: items[2],
        cpfCnpj: items[3].toString(),
        rgIE: items[4].toString(),
        cellPhone: items[5].toString(),
        phone: items[6].toString(),
        password: null,
        code: null,
        email: items[7].toString(),
        companyId: companyId,
        clientCustomer: [
          {
            address: items[8].toString(),
            number: items[9].toString(),
            state: items[11].toString(),
            city: items[10].toString(),
            zipCode: items[12].toString() || '',
            cityCode: null,
            complement: items[13].toString() || '',
            observation: items[14].toString() || '',
          },
        ],
      },
      orderProduct: products,
      orderPaymentTerm: [
        {
          amount: clearCurrency(items[21].toString()) || 0,
        },
      ],
    }

    ordersTable.push(order)
  }
  return ordersTable
}
