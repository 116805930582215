import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react'

import { Link, useLocation } from 'react-router-dom'

export const BreadCrumb = () => {
  const { pathname } = useLocation()

  const items = [
    {
      title: 'Gerência de Usuário',
      path: '/users',
    },
    {
      title: 'Cadastro de Usuário',
      path: '/users/create',
      breadCrumbs: [
        {
          title: 'Gerência de Usuário',
          route: '/users',
        },
      ],
    },
    {
      title: 'Edição de Usuário',
      path: '/users/editing',
      breadCrumbs: [
        {
          title: 'Gerência de Usuário',
          route: '/users',
        },
      ],
    },
    {
      title: 'Analítico',
      path: '/analytics',
    },
    {
      title: 'Analítico PRO',
      path: '/analytics-pro',
    },
    {
      title: 'Importador XLS',
      path: '/importador-xls',
    },
    {
      title: 'Quod',
      path: '/quod',
    },
    {
      title: 'Gerência de Arquivos',
      path: '/files',
    },
    {
      title: 'Configurações da Empresa',
      path: '/company-settings/geral',
    },
    {
      title: 'Configurações da Empresa',
      path: '/company-settings/certificate',
    },
    {
      title: 'Configurações da Empresa',
      path: '/company-settings/plan',
    },
    {
      title: 'Configurações da Empresa',
      path: '/company-settings/charge',
    },
    {
      title: 'Participantes',
      path: '/participants',
    },
    {
      title: 'Loja de Apps: Integração',
      path: '/store/integrations',
    },
    {
      title: 'Loja de Apps: Simplaz',
      path: '/store/integrations/simplaz',
    },
    {
      title: 'Loja de Apps: Importador XLS',
      path: '/store/integrations/IMPXLS',
    },
    {
      title: 'Loja de Apps: Mercado Livre',
      path: '/store/integrations/mercadolivre',
    },
    {
      title: 'Loja de Apps: Analytics BI',
      path: '/store/integrations/analyticsbi',
    },
    {
      title: 'Loja de Apps: Analytics Pro Inteligência de Vendas',
      path: '/store/integrations/intelvendas',
    },
    {
      title: 'Loja de Apps: Analytics Pro Inteligência Administrativa',
      path: '/store/integrations/inteladm',
    },
    {
      title: 'Loja de Apps: Ferramentas',
      path: '/store/tools',
    },
    {
      title: 'Gerência de Participante',
      path: '/participants/management',
      breadCrumbs: [
        {
          title: 'Participantes',
          route: '/participants',
        },
      ],
    },
  ]

  let item = items.find((f) => f.path === pathname)

  // if (item?.path === '/initial') {
  //   item = undefined
  // }

  return (
    <>
      <Box>
        <Text fontSize="sm">{item?.title || 'Início'} </Text>
        <Breadcrumb fontSize="xs" color="primary.pure">
          {item && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/initial">
                Início
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          {!!item?.breadCrumbs &&
            item?.breadCrumbs.map((bread, index) => (
              <BreadcrumbItem
                key={index}
                isLastChild={!bread.route}
                color="primary.pure"
              >
                <BreadcrumbLink
                  as={Link}
                  to={bread.route || ''}
                  isCurrentPage={!bread.route}
                >
                  {bread!?.title}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
        </Breadcrumb>
      </Box>
    </>
  )
}
