import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react'

import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { Eye, EyeSlash, Lock } from 'phosphor-react'
import { formatting } from 'src/utilities/formattingToPattern'

export const Certificate = () => {
  const { session } = useSession()
  const toast = useToast()
  const [certificate, setCertificate] = useState('')
  const [expiry, setExpiry] = useState(0)
  const [message, setMessage] = useState({ status: 0, message: '' })
  const [hidePassword, setHidePassword] = useState(true)
  const [newCertificate, setNewCertificate] = useState({
    certificadopfx: '',
    cnpjCpf: session.company.document,
    senha: '',
  })

  const ref = useRef<HTMLInputElement>(null)

  const changeCertificate = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0]

    if (file.type !== 'application/x-pkcs12') {
      toast({
        title: 'Formato inválido',
        position: 'top',
        status: 'warning',
      })
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      setNewCertificate({
        ...newCertificate,
        certificadopfx: reader.result!.toString().split(',')[1],
      })
    }
  }

  const checkCertificateIsValid = async () => {
    try {
      const { data } = await apiInstance(9000).post(
        '/workspace/Certificado',
        newCertificate,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      setMessage({ status: data.status, message: data.message })
    } catch (error: any) {
      setMessage({
        status: error.response.data.status,
        message: error.response.data.message,
      })
    }
  }

  const getCertificate = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/Certificado/Vencimento/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      setCertificate(new Date(data.dados.vencimento).toLocaleDateString())
      setExpiry(dayjs(data.dados.vencimento).diff(dayjs(), 'days'))
    } catch (error) {
      toast({
        title: <Text fontSize="sm">Falha ao carregar certificado</Text>,
        description: (
          <Text fontSize="sm">Participante sem certificado valido!</Text>
        ),
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }, [session.company.document, toast])

  useEffect(() => {
    getCertificate()
  }, [getCertificate])

  return (
    <>
      <Flex h="full" flexDir="column">
        <Text>Certificado Digital</Text>
        <Flex mt="12px" gap="24px" h="216px">
          <Box>
            <input
              ref={ref}
              type="file"
              id="file"
              style={{ display: 'none' }}
              onChange={changeCertificate}
            />
            <Center
              h="calc(100% - 48px)"
              w="270px"
              border="1px solid #E6E6E6"
              borderRadius="4px"
            >
              <Text textAlign="center" color="low.light">
                Somente <br /> certificado A1
              </Text>
            </Center>
            <Button
              onClick={() => ref.current?.click()}
              w="full"
              mt="8px"
              color="low.medium"
              fontSize="sm"
              h="36px"
              border="1px solid #bababa"
            >
              Carregar certificado
            </Button>
          </Box>

          <Flex w="full" flexDir="column">
            <Flex gap={4} align="center">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontSize="xs" mb="3px">
                  Senha do certificado
                </FormLabel>

                <InputGroup>
                  <InputLeftElement
                    children={<Lock size={22} color="#bababa" />}
                  />
                  <Input
                    placeholder="**********"
                    type={hidePassword ? 'password' : 'text'}
                    fontSize="sm"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNewCertificate({
                        ...newCertificate,
                        senha: e.currentTarget.value,
                      })
                    }
                  />
                  <InputRightElement
                    children={
                      <IconButton
                        size="sm"
                        variant="ghost"
                        onClick={() => setHidePassword(!hidePassword)}
                        aria-label="change type password"
                        children={
                          hidePassword ? (
                            <EyeSlash size={22} />
                          ) : (
                            <Eye size={22} />
                          )
                        }
                      />
                    }
                  />
                </InputGroup>
                <Text
                  h="18px"
                  fontSize="xs"
                  mt="2px"
                  color={
                    message.status === 200 ? 'success.light' : 'error.light'
                  }
                >
                  {message.message}
                </Text>
              </FormControl>
              <Button
                colorScheme="green"
                w="300px"
                onClick={checkCertificateIsValid}
              >
                Salvar
              </Button>
            </Flex>
            <Box mt="20px">
              <Text fontSize="xs" color="low.medium" mb="2">
                *A Londrisoft não se responsabiliza pelo bloqueio do certificado
                em caso de repetidas inserções de senhas incorretas.{' '}
              </Text>
              <Text fontSize="xs" color="low.medium">
                *Não é possível, em nenhuma circunstância, recuperar essa imagem
                a partir do sistema. Guarde uma cópia para futura utilização.
              </Text>
            </Box>
            <Flex gap={20} mt="auto">
              <Box>
                <Text fontSize="xs" color="low.light" mb="8px">
                  Data de vencimento
                </Text>
                <Text fontSize="sm" color="success.medium">
                  {certificate}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="low.light" mb="8px">
                  Razão social
                </Text>
                <Text fontSize="sm">{session.company.name}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" color="low.light" mb="8px">
                  Cnpj
                </Text>
                <Text fontSize="sm">
                  {formatting({
                    value: session.company.document,
                    pattern: 'document',
                  })}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Divider my="22px" color="high.dark" />

        <Flex flexDir="column" gap="20px">
          {!!certificate.length && (
            <Text fontSize="sm" color="low.medium">
              Seu certificado está próximo do vencimento, vence em{' '}
              <Text as="span" color="alert.pure" fontWeight="bold">
                {certificate}
              </Text>
              .
            </Text>
          )}
          {expiry <= 0 && (
            <Text fontSize="sm" color="#fff" bg="#ff5162" w="180px" px="5px">
              Seu certificado venceu.
            </Text>
          )}

          <Text fontSize="sm" color="low.medium">
            Não deixe para renovar após o vencimento, pois pode prejudicar sua
            emissão de Nota fiscal.
          </Text>

          <Button
            variant="link"
            as="a"
            href="https://api.whatsapp.com/send/?phone=%2B554333159335&text=Ol%C3%A1%2C+gostaria+de+renovar+o+meu+certificado+digital&type=phone_number&app_absent=0"
            target="_blank"
            w="min-content"
            fontWeight="bold"
            alignSelf="center"
            color="primary.pure"
          >
            Quero Renovar!
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
